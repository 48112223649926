import React, { useEffect } from 'react';
import ContactForm from './ContactForm';
import './contactUs.css';


function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {/* <!-- Mobile view contact form  --> */}
            <section className="d-lg-none d-block" id="contact_us">
                <div className="container">
                    <div className="tab-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 offset-lg-3 col-xs-12">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Mobile view contact form  --> */}
            <section id="login_register" class="d-lg-block d-none">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-lg-3 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs;