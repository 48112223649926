import { post, get } from "./xhr";
import { headers } from "../helpers/headers";

export const authService = {
  signIn,
  signOut,
  signUp,
  verifyMail,
  getCustomerDetails,
  updateProfile,
  emailSubscription,
  emailSubscriptionVerify,
  googleSignIn,
};

function updateProfile(url, params, headers) {
  return post(url, params, headers);
}

function googleSignIn(url, params) {
  return post(url, params);
}

function signIn(url, params) {
  return post(url, params);
}

function signOut(url, params, headers) {
  return post(url, params, headers);
}

function signUp(url, params) {
  return post(url, params);
}

function verifyMail(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/customer/verify`);
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }
  return get(url);
}

function getCustomerDetails(headers) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/customer/details`);
  return get(url, headers);
}

function emailSubscription(url, params) {
  return post(url, params);
}

function emailSubscriptionVerify(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/email-subscription/verify/${params?.id}`);

  return get(url);
}