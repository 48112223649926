import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from '../../validationSchema/loginSchema';
import { FormInput } from '../../../src/utility/FormInput';
import { FormCheckbox } from '../../../src/utility/FormCheckbox';
import { useDispatch, useSelector } from "react-redux";
import { auth, authConstant } from '../../actions/auth.action';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Message from '../../components/Message/Message';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const schema = loginSchema;

function LoginForm(props) {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);
    const localStorageData = JSON.parse(localStorage.getItem('data'));
    const [focusedInput, setFocusedInput] = useState(null);

    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const inputRef = useRef(null);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb|Login';

        if (authState.userDetails.id) {
            const redirectTo = location.state?.from?.pathname || "/profile";
            navigate(redirectTo);
        }
    }, [authState]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    const onSubmit = async (data) => {
        dispatch({ type: authConstant.SIGNIN_REQUEST });
        dispatch(auth.signIn(data));
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
        const loginPasswordEl = document.getElementById(props.id);
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password';
        loginPasswordEl.type = newType;
    };

    const handleInputFocus = (fieldName) => {
        setFocusedInput(fieldName);
    };

    const handleInputBlur = () => {
        setFocusedInput(null);
    };


    const handleGoogleSuccess = async (response) => {
        const { credential } = response;
        const decodedCredentials = jwtDecode(response.credential);

        // console.log('Login response:', response);
        // console.log('Login Success:', decodedCredentials);
        // Dispatch the token to authenticate the user
        dispatch(auth.googleSignIn({ token: credential }));
    };

    const handleGoogleFailure = (error) => {
        console.error("Google Sign-In failed:", error);
    };

    return (
        <>
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                <h3>Log in</h3>
                {(authState.error.type === 'signin') && <Message />}
                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
                    {/* Login form */}
                    <div className="form-group position-relative">
                        <FormInput
                            register={register('email')}
                            label="Email"
                            name="email"
                            type="text"
                            identity="email"
                            // autoComplete="off"
                            defaultValue={localStorageData?.email || ''}
                            onFocus={() => handleInputFocus('email')}
                            onBlur={handleInputBlur}
                            autoFocus={props.isAutoFocus}
                        />
                        {focusedInput !== 'email' && errors?.email && (
                            <div className="error-message" style={{ color: '#d62727' }}>{errors.email.message}</div>
                        )}
                    </div>

                    <div className="form-group position-relative" id="show_hide_pass">
                        <FormInput
                            register={register('password')}
                            label="Password"
                            name="password"
                            type="password"
                            identity="password"
                            autoComplete="off"
                            id={props.id}
                            defaultValue={localStorageData?.password || ''}
                            onFocus={() => handleInputFocus('password')}
                            onBlur={handleInputBlur}
                        />
                        {focusedInput !== 'password' && errors?.password && (
                            <div className="error-message" style={{ color: '#d62727' }}>{errors.password.message}</div>
                        )}
                        <div className="input-group-addon">
                            <a href="#!" onClick={togglePassword}>
                                <i className={`fa fa-eye${showPassword ? '-slash' : ''}`} aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                    <div className="form-check d-flex mt-2 pl-0 justify-content-between">
                        <div className="bottom-header-form-check mt-1">
                            <FormCheckbox
                                register={register('isRemember')}
                                label="Keep me signed in"
                                name="isRemember"
                                type="checkbox"
                                identity="isRemember"
                                autoComplete="off"
                                defaultChecked={localStorageData?.isRemember || false}
                            />
                        </div>
                        <div className="reset_pass">
                            <Link to="/login/forgot">Forgot password?</Link>
                        </div>
                    </div>

                    {authState.submitted ? (
                        <button className="btn btn-dark signin" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-dark signin">
                            Sign In
                        </button>
                    )}
                    {/* <LoginSocialGoogle
                    client_id="740397382532-qgc1ofl4h3a3iqnebbcc2eli0hpvrguq.apps.googleusercontent.com"
                    access_type="online"
                    scope="openid profile email"
                    discoveryDocs="claims supported"
                    className="google-reg"
                    onResolve={({ data }) => {
                        console.log("Google login data:", data);
                        handleSocialButton(data);
                    }}
                    onReject={(err) => {
                        console.log("Google login error:", err);
                    }}
                >
                    <GoogleLoginButton className="google" text="Login with Google" />
                </LoginSocialGoogle> */}


                </form>

                <div className="mt-3">
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleFailure}
                    // theme="filled_blue"
                    />
                </div>
            </GoogleOAuthProvider>



        </>
    );
}

export default LoginForm;
