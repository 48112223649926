import React from "react";

function FormInput({ register, label, error, ...inputProps }) {
  return (
    <>
      <input
        className={`custom-input-floating ${error ? "is-invalid" : ""}`}
        {...register}
        {...inputProps}
      />
      <label className="custom-text-floating fname" htmlFor="validationCustom01">{label}</label>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </>
  );
}

export { FormInput }