import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { homeAction, homeConstant } from '../../actions/home.action';
import CustomPrevArrow from './CustomPrevArrow';
import CustomNextArrow from './CustomNextArrow';
import './Home.css';
// import './slickJS.js';
import './Brown_font/stylesheet.css';
import { categoryAction, categoryConstant } from '../../actions/category.action';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'react-bootstrap';

// import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const dispatch = useDispatch();
    const productArr = useSelector((state) => state.homepage.data);

    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 300,
        arrow: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        /* Getting homepage data here... */
        dispatch(homeAction.getHomepageData());
    }, []);

    const handleSearch = (e, searchKey) => {
        e.preventDefault();
        let url = `/search?keyword=${searchKey}`;
        dispatch({ type: homeConstant.SET_HOMEPAGE_KEY, payload: searchKey });
        dispatch({ type: categoryConstant.SET_SEARCH_KEYWORD_VALUE, payload: '' });
        window.location.href = url;
    }
    const handleHomepageProduct = (e, hpProductKey) => {
        e.preventDefault();
        let url = `/shop/${hpProductKey}`;
        dispatch({ type: homeConstant.SET_HOMEPAGE_PRODUCT_KEY, payload: hpProductKey });
        window.location.href = url;
    }

    return (
        <div>
            <Helmet>
                <title>Welcome to Shopthumb: Your Ultimate Fashion Destination</title>
                <meta name="description" content="Shopthumb - Where Style Finds You! Discover the latest trends and fashion must-haves while browsing through our extensive collection of clothing, shoes, accessories, and more. Explore our curated selection and elevate your shopping experience today!" />
                <meta name="keywords" content="clothing, apparel, fashion, trendy clothes, men's clothing, women's clothing, kids' clothing, online shopping, fashion store, buy clothes online" />
            </Helmet>

            <div className="wrapper image-wrapper">
                <div className="main-bg ">
                    <div className="container">
                        {/*Banner Section Start*/}
                        <Carousel>
                            <Carousel.Item>
                                <div className="banner-main-block">
                                    <div className="banner-text-block">
                                        <div className="banner-block-items-content banner-text-block-items-content">
                                            <h2>Levi's</h2>
                                            <p>Levi's, the iconic American denim brand, Levi's offers timeless fashion that never lacks.</p>
                                            <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "levis")}>SEE MORE</Link>
                                        </div>
                                    </div>
                                    <div className="banner-image-block">
                                        <div className="banner-block-items-content">
                                            <div className="">
                                                <img src="images/homepage/banner.jpg" className="d-block w-100" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="banner-main-block">
                                    <div className="banner-text-block">
                                        <div className="banner-block-items-content banner-text-block-items-content">
                                            <h2>Levi's 2</h2>
                                            <p>Levi's, the iconic American denim brand, Levi's offers timeless fashion that never lacks.</p>
                                            <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "levis")}>SEE MORE</Link>
                                        </div>
                                    </div>
                                    <div className="banner-image-block">
                                        <div className="banner-block-items-content">
                                            <div className="">
                                                <img src="images/homepage/banner.jpg" className="d-block w-100" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="banner-main-block">
                                    <div className="banner-text-block">
                                        <div className="banner-block-items-content banner-text-block-items-content">
                                            <h2>Levi's 3</h2>
                                            <p>Levi's, the iconic American denim brand, Levi's offers timeless fashion that never lacks.</p>
                                            <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "levis")}>SEE MORE</Link>
                                        </div>
                                    </div>
                                    <div className="banner-image-block">
                                        <div className="banner-block-items-content">
                                            <div className="">
                                                <img src="images/homepage/banner.jpg" className="d-block w-100" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>


                        {/*Banner Section End*/}
                        {/*What to Buy Section Start*/}
                        <div className="what-to-buy-main-block">
                            <div className="what-to-buy-header">
                                <h2>
                                    <span>WHAT TO BUY NOW</span>
                                </h2>
                            </div>
                            <div className="what-to-buy-items-main-block">
                                <div className="what-to-buy-block-items">
                                    <div className="what-to-buy-block-items-content">
                                        <div className="what-to-buy-inner-block">
                                            <div className="what-to-buy-image-block">
                                                {/* <a href={productArr.products[0].product_url} target='_blank'>
                                                        <img className='homeBuyNowImg' src={`${productArr.baseUrl}${productArr.products[0].mediaImage}`} alt={productArr.products[0].name} />
                                                    </a> */}
                                                <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "skirts")}>
                                                    <img className='homeBuyNowImg' src={`images/homepage/WALLET.jpg`} alt="skirts" />
                                                </Link>
                                            </div>
                                            <div className="what-to-buy-text-block">
                                                <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "skirts")} >
                                                    <h4>Women Skirt's</h4>
                                                    <p>From mini to much variety.</p>
                                                </Link>
                                            </div>

                                        </div>
                                        <div className="what-to-buy-inner-block reverse-inner-block">
                                            <div className="what-to-buy-image-block">
                                                <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "perfume")}>
                                                    <img className='homeBuyNowImg' src={`images/homepage/WALLET.jpg`} alt="Levi's" />
                                                </Link>
                                            </div>
                                            <div className="what-to-buy-text-block">
                                                <Link className='homepageLink' to="#" onClick={(e) => handleSearch(e, "perfume")}>
                                                    <h4>Perfume</h4>
                                                    <p>Experience right perfume for every occasion.</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="what-to-buy-block-items">
                                    <div className="what-to-buy-block-items-content">
                                        <div className="what-to-buy-inner-block full-width-block">
                                            <Link className='homepageLink' to="#" onClick={(e) => handleHomepageProduct(e, "weekly_product")}>
                                                <div className="what-to-buy-image-block">
                                                    <img src="images/homepage/WALLET.jpg" alt="image" />
                                                </div>
                                                <div className="what-to-buy-text-block">
                                                    <h4 >WHAT'S NEW THIS WEEK</h4>
                                                    <p>Get the look</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="what-to-buy-block-items">
                                    <div className="what-to-buy-block-items-content">
                                        {/* <div className="what-to-buy-items-header">
                                            <h2>WHAT OUR EDITORS ARE BUYING</h2>
                                        </div> */}
                                        <div className="what-to-buy-product-block">
                                            {productArr?.featuredProduct?.length > 0 ? productArr?.featuredProduct.map((homepageData, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div className="what-to-buy-product-block-items">
                                                            <div className="what-to-buy-product-block-items-content">
                                                                <a href>
                                                                    <div className="what-to-buy-product-image-block">
                                                                        <a href={homepageData?.product_url} target='_blank'>
                                                                            <img
                                                                                className='homeBuyNowImg'
                                                                                src={homepageData.mediaImage.includes(productArr.baseUrl) ? homepageData.mediaImage : `${productArr.baseUrl}${homepageData.mediaImage}`}
                                                                                alt={homepageData.name}
                                                                            />
                                                                        </a>
                                                                    </div>

                                                                    <h4>{homepageData.brandName}</h4>
                                                                    <span>{homepageData.name}</span>
                                                                    <h5>{homepageData.retail_price}</h5>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }) : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Second part */}
                                <div className="what-to-buy-block-items">
                                    <div className="what-to-buy-block-items-content">
                                        <div className="what-to-buy-items-header">
                                            <h2 className='what-to-buy-items-text'>WHAT OUR EDITORS ARE BUYING</h2>
                                        </div>
                                        <div className="what-to-buy-product-block">
                                            {productArr?.featuredProduct?.length > 0 ? productArr?.featuredProduct.map((homepageData, index) => {
                                                if (index > 3) {
                                                    return (
                                                        <div className="what-to-buy-product-block-items">
                                                            <div className="what-to-buy-product-block-items-content">
                                                                <a href>
                                                                    <div className="what-to-buy-product-image-block">
                                                                        <a href={homepageData.product_url} target='_blank'>
                                                                            {/* <img className='homeBuyNowImg' src={`${productArr.baseUrl}${homepageData.mediaImage}`} alt={homepageData.name} /> */}
                                                                            <img className='homeBuyNowImg' src={`${productArr.baseUrl}/${homepageData?.mediaImage}`} alt={homepageData.name} />
                                                                        </a>
                                                                    </div>

                                                                    <h4>{homepageData.brandName}</h4>
                                                                    <span>{homepageData.name}</span>
                                                                    <h5>{homepageData.retail_price}</h5>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }) : ''}

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row ">
                                <div className="col text-center">
                                    <Link className='homepageLink btn btn-default ' to="#" onClick={(e) => handleHomepageProduct(e, "featured_product")}><span className='see-more-text'>SEE MORE</span></Link>
                                </div>
                            </div>
                        </div>
                        {/*What to Buy Section End*/}
                        {/*Products Section Start*/}
                        <div className="products-main-block">
                            <div className="product-header">
                                <h2>SHOPTHUMB <span>live</span></h2>
                                {/* <p>34515 SHOPPERS ONLINE</p> */}
                                <h4>People's favourite products <img src="images/homepage/heart_icon.png" alt="icon" /></h4>
                            </div>
                            <div className="product-slider-items-main-block">
                                <div className="product-slider-block">
                                    <Slider {...settings}>
                                        {productArr?.favorites?.length > 0 && productArr?.favorites.map((favoriteData, index) => {
                                            return (
                                                <div className="product-block-items">
                                                    <div className="product-block-items-content">
                                                        <a href>
                                                            <div className='imageContainer'>
                                                                <a className="ml" target="_blank" href={favoriteData?.productDetail?.product_url}>
                                                                    <img src={`${favoriteData?.productDetail?.primary_image}`} alt="image" />
                                                                </a>
                                                            </div>
                                                            <a className="fav-product-link" target="_blank" href={favoriteData?.productDetail?.product_url}>
                                                                <h3 style={{}}>{favoriteData?.productDetail?.product_name}</h3>
                                                            </a>
                                                            <p >United States</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>

                                </div>
                            </div>
                        </div>
                        {/*Products Section End*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
