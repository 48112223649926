import React, { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from '../../validationSchema/registerSchema';
import { FormInput } from '../../../src/utility/FormInput';
import { useDispatch, useSelector } from "react-redux";
import { authService } from '../../adapters/auth.services';
import { auth, authConstant } from '../../actions/auth.action';
import { useNavigate } from "react-router-dom";
import Message from '../../components/Message/Message';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


const schema = registerSchema;

function Register(props) {
    const authState = useSelector((state) => state.auth); // 
    const [showPassword, setShowPassword] = useState(true);
    const [showCnfPassword, setShowCnfPassword] = useState(true);
    const [focusedInput, setFocusedInput] = useState(null);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Register'
        if (authState.userDetails.flag === true) {
            dispatch(auth.updateRegisterFlag(authState.userDetails.email));

            return navigate("/register-success");
        }
    }, [authState]);

   const inputRef = useRef(null);

   useEffect(()=>{
    if(inputRef.current){
        inputRef.current.focus();
    }
   },[])

    const onSubmit = (data) => {
        dispatch({ type: authConstant.SIGNUP_REQUEST });
        dispatch(auth.signUp(data));

    }

    const togglePassword = () => {
        setShowPassword(showPassword === true ? false : true)
        const loginPasswordEl = document.getElementById(props.pId);
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }
    const togglePasswordCnf = () => {
        setShowCnfPassword(showCnfPassword === true ? false : true)
        const loginPasswordEl = document.getElementById(props.cnfId);
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }

    const handleInputFocus = (fieldName) => {
        setFocusedInput(fieldName);
    }

    const handleInputBlur = () => {
        setFocusedInput(null);
    }

    const handleGoogleSuccess = async (response) => {
        const { credential } = response;
        const decodedCredentials = jwtDecode(response.credential);

        // console.log('Login response:', response);
        // console.log('Login Success:', decodedCredentials);
        // Dispatch the token to authenticate the user
        dispatch(auth.googleSignIn({ token: credential }));
    };

    const handleGoogleFailure = (error) => {
        console.error("Google Sign-In failed:", error);
    };

    return (
        <>
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                {(authState.error.type === 'signup') ? <Message /> : ''}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row mb-3 mx-0">
                        <div className="form-group position-relative col-lg-6 mb-3 pl-lg-0 pr-lg-1 px-0">
                            <FormInput
                                id='first_name'
                                register={register('first_name')}
                                label="First Name"
                                // error={errors?.first_name}
                                name="first_name"
                                type="text"
                                // autoComplete="off"
                                identity="first_name"
                                onFocus={() => handleInputFocus('first_name')}
                                onBlur={handleInputBlur}
                                // autoFocus={props.isAutoFocus}

                            />
                            {focusedInput !== 'first_name' && errors?.first_name && (
                                <div className="error-message" style={{ color: '#d62727' }}>{errors?.first_name?.message}</div>
                            )}
                        </div>

                        <div className="form-group position-relative col-lg-6 pl-lg-1 px-0">
                            <FormInput
                                register={register('last_name')}
                                label="Last Name"
                                // error={errors?.last_name}
                                name="last_name"
                                type="text"
                                identity="last_name"
                                autoComplete="off"
                                onFocus={() => handleInputFocus('last_name')}
                                onBlur={handleInputBlur}
                            />
                            {focusedInput !== 'last_name' && errors?.last_name && (
                                <div className="error-message" style={{ color: '#d62727' }}>{errors?.last_name?.message}</div>
                            )}
                        </div>
                    </div>

                    <div className="form-group position-relative">
                        <FormInput
                            register={register('email')}
                            label="Email"
                            // error={errors?.email}
                            name="email"
                            type="text"
                            identity="email"
                            autoComplete="off"
                            onFocus={() => handleInputFocus('email')}
                            onBlur={handleInputBlur}
                        />
                        {focusedInput !== 'email' && errors?.email && (
                            <div className="error-message" style={{ color: '#d62727' }}>{errors?.email?.message}</div>
                        )}
                    </div>

                    <div className="form-group position-relative" id="show_hide_password">
                        <FormInput
                            register={register('password')}
                            label="Password"
                            // error={errors?.password}
                            name="password"
                            type="password"
                            identity="password"
                            autoComplete="off"
                            id={props.pId}
                            onFocus={() => handleInputFocus('password')}
                            onBlur={handleInputBlur}
                        />
                        {focusedInput !== 'password' && errors?.password && (
                            <div className="error-message" style={{ color: '#d62727' }}>{errors?.password?.message}</div>
                        )}

                        <div className="input-group-addon">
                            <a href onClick={() => togglePassword()}><i className={`fa fa-eye${showPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                        </div>
                    </div>

                    <div className="form-group position-relative" id="show_hide_password2">
                        <FormInput
                            register={register('confirm_password')}
                            label="Confirm Password"
                            // error={errors?.confirm_password}
                            name="confirm_password"
                            type="password"
                            identity="confirm_password"
                            autoComplete="off"
                            id={props.cnfId}
                            onFocus={() => handleInputFocus('confirm_password')}
                            onBlur={handleInputBlur}
                        />
                        {focusedInput !== 'confirm_password' && errors?.confirm_password && (
                            <div className="error-message" style={{ color: '#d62727' }}>{errors?.confirm_password?.message}</div>
                        )}
                        <div className="input-group-addon">
                            <a href id="showhide" onClick={() => togglePasswordCnf()}><i className={`fa fa-eye${showCnfPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <p className="agree">By signing up for an account you accept our <a href="#" className="terms">Terms</a> and <a href="#" className="terms">Privacy Policy</a>.</p>
                    {authState.regSubmitted === true && (
                        <button className="btn btn-dark signup" disabled>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>{" "}
                            Loading...
                        </button>
                    )}
                    {authState.regSubmitted === false && (
                        <button type="submit" className="btn btn-dark signin">REGISTER</button>
                    )}

                    {/* <div className="bottom-header-form-check">
                    <label className="box-check">I’d like to hear from Shopacus. Unsubscribe anytime.
                        <input type="checkbox" checked="checked" />
                        <span className="checkmark"></span>
                    </label>
                    </div> */}

                </form>
                <div className="mt-3">
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleFailure}
                    // theme="filled_blue"
                    />
                </div>
            </GoogleOAuthProvider>
        </>
    );
}

export default Register;