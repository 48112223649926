import React,{useState} from 'react'
import './style.css'
import { Link } from 'react-router-dom'
// import './foundation.min.css'

const SideMenu = (props) => {

    const [activeCategory, setActiveCategory] = useState(null);

    const handleActiveCategory = (slug) => {
        setActiveCategory(slug);
    };

    return (
        <div id="sidebar" class="sidebar" style={{fontFamily:'sans-serif'}}>
            <button class="close-btn" id="close-sidebar">&times;</button>
            <div id="menu" class="menu-container">
                <div id="menu" >
                    <ul id="example-menu" class="menu drilldown" data-drilldown>
                        <li className='Main-category'> {props.allCategories?.data?.length > 0 && props.allCategories.data.map((allcateg, index) => {
                            return (
                                <li key={index}>
                                    <a href='#'>
                                        {allcateg.name}
                                    </a>
                                    <hr />
                                    <ul className='vertical menu '>
                                        {allcateg.subCateg?.length > 0 && allcateg.subCateg.map((subCateg, index) => {
                                            if (subCateg.childSubCateg.length > 0) {
                                                return (
                                                    <li>
                                                        <a href='#'>{subCateg.name}</a>
                                                        <hr className='subCateg-divider' />
                                                        <ul>
                                                            {subCateg.childSubCateg.length > 0 && subCateg.childSubCateg.map((childSubCateg, index) => {
                                                                const slugPath = `/browse/${allcateg.slug}/${subCateg.slug}/${childSubCateg.slug}`;
                                                                return (
                                                                    <>
                                                                        <li key={index} id="childSubCateg" className={activeCategory === slugPath ? 'active' : ''}><Link id="close-sidebar" to={`/browse/${childSubCateg.slug}`} onClick={(e) => {
                                                                            props.handleCategoryFilter(e, slugPath);
                                                                            handleActiveCategory(slugPath);
                                                                            const sidebar = document.getElementById("sidebar");
                                                                            sidebar.classList.remove("active");
                                                                        }}>{childSubCateg.name}</Link></li>
                                                                        <hr className='subCateg-divider' />
                                                                    </>

                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}>
                                                        <Link to={`/browse/${allcateg.slug}/secondary/${subCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allcateg.slug + '/' + subCateg.slug)}>{subCateg.name}</Link>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                        </li>

                        {/* <li>
                            <a href="#">Item 1</a>
                            <ul class="vertical menu">
                                <li>
                                    <a href="#">Item 1A</a>
                                    <ul class="vertical menu">
                                        <li><a href="#">Item 1A</a></li>
                                        <li><a href="#">Item 1B</a></li>
                                        <li><a href="#">Item 1C</a></li>
                                        <li><a href="#">Item 1D</a></li>
                                        <li><a href="#">Item 1E</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Item 1B</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Item 2</a>
                            <ul class="vertical menu">
                                <li><a href="#">Item 2A</a></li>
                                <li><a href="#">Item 2B</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Item 3</a>
                            <ul class="vertical menu">
                                <li><a href="#">Item 3A</a></li>
                                <li><a href="#">Item 3B</a></li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideMenu